import CompraApi from '../../resources/api-compra';
import CotacaoFornecedor from '../models/cotacaofornecedor';

/**
 * API de Cotação de Compras
 *
 * @author Marcos Davi <marcos.davi@bubo.tech>
 * @extends {RootApi}
 */
class CotacaoComprasAPI extends CompraApi<CotacaoFornecedor> {
  /**
  * Injeta a instância do axios com o endpoint base referente a Cotação Compras
  */
  public constructor(schema?: string) {
    super('/cotacao', schema);
  }

  public async searchCotacao(id?: string) {
    return this.api.get(`/busca-cotacao-fornecedor/${id}`);
  }

  public async saveCotacao(values: CotacaoFornecedor) {
    return this.api.put('/salvar-cotacao-fornecedor', values);
  }

  public async findFormaPgto() {
    
  }
}

export default CotacaoComprasAPI;
