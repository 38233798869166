import { makeStyles } from "@mui/styles";

/**
 * Estilos para aba mapa de cotação
 * 
 * @author Marcos Davi <marcos.davi@bubo.tech>
 */
const useStyles = makeStyles((theme: any) => ({
  tabContent: {
    padding: '25px 21px 5px',
    flex: 1,
    overflow: 'auto',
    height: '80%',
  },
  groupsContainer: {
    height: 40,
    background: '#f0f4f8',
    borderTop: '1px solid #dae3ee',
    borderBottom: '1px solid #dae3ee',
    width: '100%',
    display: 'flex'
  },
  header: {
    padding: '0 10px',
    borderRight: '2px solid #dae3ee',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    fontWeight: 700,
    fontFamily: 'Open Sans',
    color: '#95a2b9',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  cell: {
    fontWeight: 400,
    color: '#000'
  },
  rowContainer: {
    maxHeight: 'calc(100vh - 380px)', 
    overflowY: 'scroll',
  },
  container: {
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    fontSize: 16,
    color: `${theme.palette.primary.dark}`,
    marginBottom: 15
  },
  totalBlock: {
    backgroundColor: `${theme.palette.primary.main}`,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    borderRadius: 10,
    color: '#FFF',
    fontWeight: 600,
    alignItems: 'center',
    paddingBottom: 20,
    marginBottom: 25,
    textAlign: 'center',
    '& p': {
      fontWeight: 400,
      fontSize: 12
    }
  },
  buttonContainer: {
    display: 'flex', 
    justifyContent: 'flex-end',
    padding: '10px 20px', 
  },
  button: {
    margin: '0px 10px !important',
    color: `${theme.palette.secondary.main} !important`,
    background: `${theme.palette.primary.main} !important`,
  }
}));

export { useStyles };