import URL from '../../resources/URL';
import GenericApi from '../../resources/generic-api';


/**
 * Classes para buscar informações referentes ao Módulo Compra
 *
 * @author Marcos Davi <marcos.davi@bubo.tech>
 */
export default class CompraApi<E> extends GenericApi<E> {
  /**
   * Injeta as configurações da api
   */
  public constructor(value?: string, schema?: string) {
    super({ baseURL: `${URL.COMPRAAPI + (value ?? '')}`, baseSchema: schema });
  }
}
