import React, { useEffect, useState } from 'react';
import { EditableTable, Form, Modal, Swal } from '../../../components';
import { useStyles } from '../assets/styles/fornecedor.styles';
import { FormFieldTypeEnum } from '../../../components/form/form';
import FormaPagamentoAPI from '../../../resources/faturamento/formapgto';
import CondicaoPagamentoAPI from '../../../resources/faturamento/condicaopgto';
import FreteContaEnum from '../enum/tipo-frete-conta';
import { numberToString, stringToNumber } from '../../../utils/numbers';
import TipoPagamentoEnum from '../enum/tipo-pagamento-enum';
import { useFormik } from 'formik';
import CotacaoFornecedor from '../models/cotacaofornecedor';
import ItemCotacaoFornecedor from '../models/itemcotacaofornecedor';

import CotacaoComprasAPI from '../resources/api';

import Header from '../assets/images/cotacao-header.png';
import { useParams } from 'react-router-dom';
import { Loading } from '@bubotech/sumora-react-components';
import formatarCpfCnpj from '../../../utils/format-document';
import { Button, Grid } from '@mui/material';
import moment from 'moment';
import * as Yup from 'yup';
import { MaskTypeEnum } from '@bubotech/sumora-react-components/lib/maskedtextfield';


/**
 * Tela enviada para o fornecedor
 * 
 * @author Marcos Davi <marcos.davi@bubo.tech>
 */
function LinkFornecedor(): JSX.Element {
  const classes = useStyles();
  const { baseSchema, id } = useParams();

  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [enviaNovamente, setEnviaNovamente] = useState<boolean>(false);

  const api = new CotacaoComprasAPI(baseSchema);
  const formaPgtoApi = new FormaPagamentoAPI(baseSchema);
  const condicaoPgtoApi = new CondicaoPagamentoAPI(baseSchema);

  const form = useFormik<CotacaoFornecedor>({
    initialValues: {
      itemCotacaoFornecedorList: [],
      nrParcela: 0,
      stRegistro: 0,
      tpFrete: 0,
      tpPgto: 0,
      vlTotalCotacao: 0,
      vlTotalFrete: 0,
      nmVendedor: '',
      nrTelefoneVendedor: '',
      dsEmailVendedor: ''
    },
    validationSchema: Yup.object().shape({
      nmVendedor: Yup.string().nullable().required('Campo obrigatório'),
      dsEmailVendedor: Yup.string().nullable().email('E-mail inválido').required('Campo obrigatório'),
      nrTelefoneVendedor: Yup.string().nullable().required('Campo obrigatório'),
      formaPgto: Yup.object().nullable().required('Campo obrigatório'),
      condicaoPgto: Yup.object().nullable().required('Campo obrigatório')
    }),
    onSubmit: async () => {
      setLoading(true);
      await api.saveCotacao({
        ...form.values,
        itemCotacaoFornecedorList: form.values.itemCotacaoFornecedorList.map(item => ({
          ...item,
          dtEntrega: moment(item.dtEntrega).format('YYYY-MM-DD')
        }))
      })
        .then(() => {
          Swal({
            icon: 'success',
            title: 'Sucesso',
            text: 'Cotação enviada para comprador!',
            showConfirmButton: true,
            confirmButtonText: 'OK'
          });
          setEnviaNovamente(true);
        })
        .catch(() => Swal({
          icon: 'error',
          title: 'Erro',
          text: 'Houve um problema ao tentar enviar a cotação!',
          showConfirmButton: true,
          confirmButtonText: 'OK'
        }))

      setLoading(false);
    }
  });

  function handleTotal(item: ItemCotacaoFornecedor) {
    let vlUnitario = item.vlUnitario;

    if (item.vlIpi)
      vlUnitario += item.vlIpi;

    if (item.vlDesconto)
      vlUnitario -= item.vlDesconto;

    return (vlUnitario * item.nrQtde) + item.vlFrete;
  }

  // Função para mudar o valor de um campo númerico de um item
  function handleChange(field: string, value: string, index: number) {
    const newData = [...form.values.itemCotacaoFornecedorList];

    newData[index] = {
      ...newData[index],
      [field]: stringToNumber(value),
    };
    newData[index].vlTotal = handleTotal(newData[index]);
    form.setFieldValue('itemCotacaoFornecedorList', newData);
  }

  useEffect(() => {
    let vlDesconto = 0
    setLoading(true);
    api.searchCotacao(id).then(res => {
      form.setValues({
        ...res.data,
        itemCotacaoFornecedorList: res.data.itemCotacaoFornecedorList.map((item: ItemCotacaoFornecedor) => {
          vlDesconto += (item.nrQtde * item.vlDesconto);
          return {
            ...item,
            vlTotal: handleTotal(item),
            dtEntrega: moment(item.dtEntrega)
          }
        })
      });
      form.setFieldValue('vlTotalDesconto', vlDesconto);
    })
    .catch(() => {

    })
    .finally(() => setLoading(false))
  }, [])

  // Função para calcular o valor de um campo apartir de uma porcentagem inserido
  function handleChangePercentage(percentage: string, value: string, field: string, index: number) {
    const newData = [...form.values.itemCotacaoFornecedorList];
    let vlUnitario = newData[index].vlUnitario;

    if (percentage === 'pcIpi')
      vlUnitario -= newData[index].vlDesconto;

    const fieldValue = (stringToNumber(value) / 100) * vlUnitario;
    newData[index] = {
      ...newData[index],
      [percentage]: stringToNumber(value),
      [field]: fieldValue,
    };
    newData[index].vlTotal = handleTotal(newData[index]);
    form.setFieldValue('itemCotacaoFornecedorList', newData);
  }

  // Função para calcular a porcentagem de um campo apartir do valor númerico inserido
  function handleChangeValue(percentage: string, value: string, field: string, index: number) {
    const newData = [...form.values.itemCotacaoFornecedorList];
    let vlUnitario = newData[index].vlUnitario;

    if (percentage === 'pcIpi')
      vlUnitario -= newData[index].vlDesconto;

    const nrPercentage = (100 * stringToNumber(value)) / vlUnitario;
    newData[index] = {
      ...newData[index],
      [field]: stringToNumber(value),
      [percentage]: nrPercentage
    };
    newData[index].vlTotal = handleTotal(newData[index]);
    form.setFieldValue('itemCotacaoFornecedorList', newData);
  }

  function handleChangeFrete(item: string, index: number) {
    const newData = [...form.values.itemCotacaoFornecedorList];

    newData[index].vlFrete = stringToNumber(item);
    newData[index].vlTotal = handleTotal(newData[index]);
    form.setFieldValue('itemCotacaoFornecedorList', newData);
  }

  function handleChangeTotal(item: string, index: number) {
    const newData = [...form.values.itemCotacaoFornecedorList];

    newData[index].vlTotal = stringToNumber(item);
    form.setFieldValue('itemCotacaoFornecedorList', newData);
  }

  return (
    <main style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Loading show={loading}/>
      <img src={Header} alt='cotacao-header' style={{ width: '100%' }} />
      <section className={classes.tabContent}>
        <Form
          form={form}
          fields={[
            {
              fieldName: 'fornecedor.nrDocumento',
              fieldSize: 2,
              fieldType: FormFieldTypeEnum.READ_ONLY,
              label: 'CPF ou CNPJ',
              value: form.values.fornecedor?.nrDocumento ? formatarCpfCnpj(form.values.fornecedor?.nrDocumento) : '-'
            },
            {
              fieldName: 'fornecedor.nmPessoa',
              fieldSize: 5,
              fieldType: FormFieldTypeEnum.READ_ONLY,
              label: 'Fornecedor'
            },
            {
              fieldName: 'emailFornecedor',
              fieldSize: 3,
              fieldType: FormFieldTypeEnum.READ_ONLY,
              label: 'E-mail',
            },
            {
              fieldName: 'fornecedor.nrTelefone',
              fieldSize: 2,
              fieldType: FormFieldTypeEnum.READ_ONLY,
              label: 'Telefone'
            },
            {
              fieldName: 'nmVendedor',
              fieldSize: 4,
              fieldType: FormFieldTypeEnum.TEXTFIELD,
              label: 'Nome Vendedor'
            },
            {
              fieldName: 'dsEmailVendedor',
              fieldSize: 3,
              fieldType: FormFieldTypeEnum.TEXTFIELD,
              label: 'E-mail'
            },
            {
              fieldName: 'nrTelefoneVendedor',
              fieldSize: 2,
              fieldType: FormFieldTypeEnum.MASKED_TEXTFIELD,
              label: 'Telefone',
              maskedTextFieldProps: {
                typeMask: MaskTypeEnum.TELEPHONE
              }
            }
          ]}
        />
        <Grid className={classes.container} xs={12} style={{ marginBottom: 40 }} />
        <div style={{ minHeight: '40%' }}>
        <EditableTable
          showDelete={false}
          data={form.values.itemCotacaoFornecedorList}
          cols={[
            {
              header: 'Produto',
              xs: 3,
              value: item => item.itemCotacao?.produto?.nmProduto ?? ''
            },
            {
              header: 'Qtde.',
              xs: 1,
              value: item => numberToString(item.nrQtde, 2),
              onChangeItem: (item, index) => handleChange('nrQtde', item, index)
            },
            {
              header: 'Unit. (R$)',
              value: item => numberToString(item.vlUnitario, 2),
              editable: 'masked',
              xs: 1,
              onChangeItem: (item, index) => handleChange('vlUnitario', item, index)
            },
            {
              header: 'IPI (%)',
              value: item => item.pcIpi,
              xs: 1,
              editable: 'masked',
              onChangeItem: (item, index) => handleChangePercentage('pcIpi', item, 'vlIpi', index)
            },
            {
              header: 'IPI (R$)',
              value: item => item.vlIpi,
              editable: 'masked',
              xs: 1,
              onChangeItem: (item, index) => handleChangeValue('pcIpi', item, 'vlIpi', index)
            },
            {
              header: 'Desc. (%)',
              value: item => item.pcDesconto,
              editable: 'masked',
              xs: 1,
              onChangeItem: (item, index) => handleChangePercentage('pcDesconto', item, 'vlDesconto', index)
            },
            {
              header: 'Desc. (R$)',
              value: item => item.vlDesconto,
              editable: 'masked',
              xs: 1,
              onChangeItem: (item, index) => handleChangeValue('pcDesconto', item, 'vlDesconto', index)

            },
            {
              header: 'Entrega',
              value: item => item.dtEntrega,
              editable: 'date',
              xs: 1,
              onChangeItem(item, index) {
                const newData = [...form.values.itemCotacaoFornecedorList];

                newData[index].dtEntrega = item;
                form.setFieldValue('itemCotacaoFornecedor', newData);
              },
            },
            {
              header: 'Frete (R$)',
              value: item => item.vlFrete,
              editable: 'masked',
              xs: 1,
              onChangeItem: handleChangeFrete,
            },
            {
              header: 'Total (R$)',
              value: item => item.vlTotal,
              editable: 'masked',
              xs: 1,
              onChangeItem: handleChangeTotal,
            }
          ]}
        />
        </div>
        <div style={{ display: 'flex', gap: 20 }}>
          <div style={{ flex: 5 }}>
            <Form
              fields={[
                {
                  label: 'Forma de Pagamento',
                  fieldName: 'formaPgto',
                  fieldSize: 4,
                  fieldType: FormFieldTypeEnum.AUTOCOMPLETE,
                  autoCompleteProps: {
                    getLabel: opt => opt.nmFormaPgto,
                    getValue: opt => opt.idFormaPgto,
                    PaperSuggestionsProps: {
                      className: 'suggestions-xs-2'
                    },
                    genericApi: formaPgtoApi,
                    searchField: 'nmFormaPgto',
                    orderField: 'nmFormaPgto',
                    staticSearchParams: ',stFormaPgto:1'
                  }
                },
                {
                  label: 'Condição de Pagamento',
                  fieldName: 'condicaoPgto',
                  fieldSize: 4,
                  fieldType: FormFieldTypeEnum.AUTOCOMPLETE,
                  autoCompleteProps: {
                    getLabel: opt => opt.nmCondicaoPgto,
                    getValue: opt => opt.idCondicaoPgto,
                    PaperSuggestionsProps: {
                      className: 'suggestions-xs-2'
                    },
                    genericApi: condicaoPgtoApi,
                    searchField: 'nmCondicaoPgto',
                    orderField: 'nmCondicaoPgto',
                    staticSearchParams: ',stCondicaoPgto:1'
                  }
                },
                {
                  label: 'Condição baseada em',
                  fieldName: 'condicaoBaseada',
                  fieldSize: 2,
                  fieldType: FormFieldTypeEnum.TEXTFIELD,
                },
                {
                  label: 'Tipo de Pagamento',
                  fieldName: 'tpPgto',
                  fieldSize: 2,
                  fieldType: FormFieldTypeEnum.SELECT,
                  selectProps: {
                    getOptionLabel: opt => opt.label,
                    getOptionValue: opt => opt.value,
                    options: [
                      { label: 'A Vista', value: TipoPagamentoEnum.A_VISTA },
                      { label: 'A Prazo', value: TipoPagamentoEnum.A_PRAZO },
                    ],
                    placeholder: 'Selecione...'
                  }
                },
                {
                  label: 'Qtde. Parcelas',
                  fieldName: 'condicaoPgto.nrMaximoParcela',
                  fieldSize: 1,
                  fieldType: FormFieldTypeEnum.READ_ONLY,

                },
                {
                  label: 'Primeiro Vencimento (dias)',
                  fieldName: 'condicaoPgto.nrPrimeiroPgto',
                  fieldSize: 3,
                  fieldType: FormFieldTypeEnum.READ_ONLY,
                },
                {
                  label: 'Valor Total Desconto (R$)',
                  fieldName: 'vlTotalDesconto',
                  fieldSize: 3,
                  fieldType: FormFieldTypeEnum.MASKED_TEXTFIELD,
                  maskedTextFieldProps: {
                    onChange: (event: any) => {
                      form.setFieldValue('vlTotalDesconto', stringToNumber(event.target.value));
                    }
                  }
                },
                {
                  label: 'Tipo Frete',
                  fieldName: 'tpFrete',
                  fieldSize: 1,
                  fieldType: FormFieldTypeEnum.SELECT,
                  selectProps: {
                    getOptionLabel: opt => opt.label,
                    getOptionValue: opt => opt.value,
                    options: [
                      { label: 'CIF', value: FreteContaEnum.CIF },
                      { label: 'FOB', value: FreteContaEnum.FOB },
                    ],
                    placeholder: 'Selecione...'
                  }
                },
                {
                  label: 'Valor Total Frete (R$)',
                  fieldName: 'vlTotalFrete',
                  fieldSize: 2,
                  fieldType: FormFieldTypeEnum.MASKED_TEXTFIELD,
                  maskedTextFieldProps: {
                    onChange: (event: any) => {
                      form.setFieldValue('vlTotalFrete', stringToNumber(event.target.value))
                    }
                  }
                },
              ]}
              form={form}
            />
          </div>
          <div className={classes.totalBlock}>
            Valor Total
            <br />
            R$ {numberToString((form.values.itemCotacaoFornecedorList).reduce((count, item) => count + item.vlTotal, 0), 2)}
          </div>
        </div>
        <Grid className={classes.container} xs={12} />
        <Grid xs={12} display={'flex'} justifyContent={'flex-end'}>
          <Button className={classes.button} onClick={() => setShowModal(true)}>
            {enviaNovamente ? 'Enviar nova cotação' : 'Enviar'}
          </Button>
        </Grid>
      </section>
      <Modal 
        open={showModal}
        title='Você está enviando os dados para o Sistema Piani'
        optionsDescription='Deseja confirmar esse envio?'
        onCloseLabel='CANCELAR'
        onFinishLabel='CONFIRMAR'
        onClose={() => setShowModal(false)}
        onFinish={() => {
          setShowModal(false);
          form.handleSubmit();
        }}
      />
    </main>
  );
}

export default LinkFornecedor;