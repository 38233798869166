/**
 * Formata uma string de cpf ou cnpj
 * 
 * @param cpfCnpj string de cpf ou cnpj que será formatada
 * @returns {string} cpf ou cnpj formatado
 * @author Daniel Fonseca <daniel.silva@kepha.com.br>
 */
export default function formatarCpfCnpj(cpfCnpj: string): string {
  if (cpfCnpj.length === 11) {
      return cpfCnpj.replace(
          /(\d{3})(\d{3})(\d{3})(\d{2})/,
          '$1.$2.$3-$4',
      );
  }

  return cpfCnpj.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      '$1.$2.$3/$4-$5',
  );
}

/**
* Retira a formatação de uma string de cpf ou cnpj
* 
* @param cpfCnpj string de cpf ou cnpj que será desformatada
* @returns {string} cpf ou cnpj sem formatação
* @author Daniel Fonseca <daniel.silva@kepha.com.br>
*/
export function desformatarCpfCnpj(cpfCnpj: string): string {
  return cpfCnpj.replace(/\D/g, '');
}