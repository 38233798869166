/**
 * Enum de um tipo de pagamento
 *
 * @author Marcos Davi
 */
enum TipoPagamentoEnum {
  A_VISTA,
  A_PRAZO
}

export default TipoPagamentoEnum;
