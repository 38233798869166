/**
 * Responsável pela memória das URL's das API's do sistema
 *
 * @author Marcos Davi <marcos.davi@bubo.tech>
 * @abstract
 */
abstract class URL {
  /**
   * URL da API de autenticação
   *
   * @static
   */
  public static readonly OAUTH = process.env.REACT_APP_URL_OAUTH;

  /**
   * URL da API principal
   *
   * @static
   */
  public static readonly API = process.env.REACT_APP_PIANI_API_URL_GENERAL;

  /**
   * URL da API FATURAMENTO
   * 
   * @static
   */
  public static readonly FATURAMENTOAPI = process.env.REACT_APP_PIANI_API_URL_FATURAMENTO;

  /**
   * URL da API de COMPRA
   * 
   * @static
   */
  public static readonly COMPRAAPI = process.env.REACT_APP_PIANI_API_URL_COMPRA;
}

export default URL;