import React, { useState } from 'react';
import { useStyles } from './editabletable.styles';
import { Grid, TextFieldProps } from '@mui/material';
import { AutoCompletePropType } from '@bubotech/sumora-react-components/lib/autocomplete';
import { AutoComplete, DatePicker, TextField, MaskedTextField, GroupButtonGrid } from '@bubotech/sumora-react-components';
import { MaskTypeEnum, MaskedTextFieldPropType } from '@bubotech/sumora-react-components/lib/maskedtextfield';
import { RowClickedEvent } from 'ag-grid-community';
import { theme } from '../../utils/constants';
import { GroupButtonGridPropType } from '@bubotech/sumora-react-components/lib/groupbuttongrid';

type EditType = "input" | "date" | "autocomplete" | "masked";

interface AutoCompleteProp extends Omit<AutoCompletePropType<any>, 'staticSearchParams'> {
  staticSearchParams?: string | ((filter: number) => string);
  key?: number;
}

export interface AutoCompleteSuggestions {
  [key: number]: any[];
}

interface ColumnDefinition<T> {
  header: string; // título da coluna
  xs: number; // largura da coluna
  editable?: EditType; // tipo de componente de edição
  autocompleteProps?: AutoCompleteProp; // propriedades do autocomplete
  inputProps?: TextFieldProps; // propriedades do campo de texto
  maskedProps?: MaskedTextFieldPropType; // propriedades do campo com máscara (default: money)
  // lista de sugestões para um autocomplete (usar quando as sugestões da coluna dependerem do valor de outra coluna)
  autocompleteSuggestions?: AutoCompleteSuggestions;
  disableDate?: boolean;
  value: (node: T) => any;
  onChangeItem?: (item: any, index: number) => void;
  onClickRow?: (index: number) => void;
}


interface EditableTablePropType<T> extends Omit<GroupButtonGridPropType, 'onClickEdit' | 'onClickDelete' | 'onClickAdd' | 'customButtons'> {
  cols: ColumnDefinition<T>[];
  data: T[];
  setSelected?: (selected: T | undefined, event?: RowClickedEvent) => void;
  showAdd?: boolean;
  showDelete?: boolean;
  showEdit?: boolean;
  buttonDeleteProps?: any;
  enableDeleteWithSelected?: boolean;
  onClickEdit?: (selected: T) => void;
  onClickAdd?: () => void;
  onClickDelete?: (selected: T) => void;
  enableAddWithSelected?: boolean;
  enableEditWithSelected?: boolean;
  className?: string;
  customButtons?: JSX.Element[];
  fullScreen?: boolean;
  enableControls?: boolean;
  nextTab?: boolean;
  previousTab?: boolean;
  addShortcut?: () => void;
}

export interface TableState<T> {
  selected?: T;
  showDelete: boolean;
  selectedIndex: number;
  key: number;
}

/**
 * Componenente de tabela editável
 * 
 * @author Marcos Davi <marcos.davi@kepha.com.br>
 * @param {EditableTablePropType} props
 */
function EditableTable<T>(props: EditableTablePropType<T>): JSX.Element {
  const classes = useStyles();
  const {
    showDelete = true,
    showEdit = false,
    buttonDeleteProps,
    buttonEditProps,
    cols,
    data,
    enableDeleteWithSelected = true,
    enableEditWithSelected = true,
    onClickDelete,
    onClickEdit,
    customButtons,
  } = props;
  const [tableState, setTableState] = useState<TableState<T>>({
    selected: undefined,
    showDelete: false,
    selectedIndex: -1,
    key: 0
  });

  const [selectedIndex, setSelectedIndex] = useState<number>();

  function getRowStyle(index: number) {
    if (index === selectedIndex) return classes.selectedRow;

    if (index % 2 === 0) return classes.evenRow;
    else return classes.oddRow;
  }

  function getStaticParams(filter: number, props?: AutoCompleteProp) {
    if (!props || !props.staticSearchParams) return '';

    if (typeof props.staticSearchParams === 'string') return props.staticSearchParams;
    else return props.staticSearchParams(filter);
  }

  function showEditComponent(
    type: EditType,
    index: number,
    autoCompleteProps?: AutoCompleteProp,
    value?: any,
    handleChange?: (item: any, index: number) => void,
    autoCompleteSuggestions?: AutoCompleteSuggestions,
    inputProps?: TextFieldProps,
    maskedProps?: MaskedTextFieldPropType,
    disableDate?: boolean,
  ) {
    switch (type) {
      case 'input':
        return (
          <TextField
            variant='standard'
            className={classes.input}
            value={value}
            onChange={e => handleChange && handleChange(e.target.value, index)}
            {...inputProps}
          />
        );
      case 'autocomplete':
        return (
          <AutoComplete
            PaperSuggestionsProps={{ className: 'suggestions' }}
            getLabel={autoCompleteProps?.getLabel ?? ''}
            getValue={autoCompleteProps?.getLabel ?? ''}
            onChangeValue={(e) => handleChange && handleChange(e, index)}
            suggestions={autoCompleteSuggestions && autoCompleteSuggestions[index] ? autoCompleteSuggestions[index] : []}
            {...autoCompleteProps}
            staticSearchParams={getStaticParams(index, autoCompleteProps)}
            value={value ?? undefined}
          />
        );
      case 'date':
        return (
          <DatePicker
            className={classes.input}
            value={value ?? null}
            onChange={(e: Date | null) => handleChange && handleChange(e, index)}
            disabled={disableDate}
          />
        );
      case 'masked':
        return (
          <MaskedTextField
            typeMask={MaskTypeEnum.MONEY}
            className={classes.input}
            value={value as string}
            onChange={e => handleChange && handleChange(e.target.value, index)}
            {...maskedProps}
          />
        );
    }
  }

  const handleSelectIndex = (index: number) => {
    if (index === selectedIndex) {
      setSelectedIndex(undefined);
      if (props.setSelected) props.setSelected(undefined);
      setTableState(prev => ({ ...prev, key: prev.key + 1, selected: undefined }));
    }
    else {
      setSelectedIndex(index);
      if (props.setSelected) props.setSelected(data[index]);
      setTableState(prev => ({ ...prev, key: prev.key + 1, selected: data[index] }));
    }
  };

  function handleClickDelete() {
    if (!tableState.selected || !onClickDelete) return;

    onClickDelete(tableState.selected);
    setTableState(prev => ({ ...prev, showDelete: false }));
  }

  function handleClickEdit() {
    if (!tableState.selected || !onClickEdit) return;

    onClickEdit(tableState.selected);
  }

  return (
    <section className={classes.container}>
      <GroupButtonGrid
        showAdd={false}
        showEdit={showEdit}
        showDelete={showDelete}
        onClickDelete={handleClickDelete}
        onClickEdit={handleClickEdit}
        buttonEditProps={{
          ...buttonEditProps,
          backgroundColor: theme.palette.primary.main,
          disabledColor: theme.palette.grey['100'],
          disabled: enableEditWithSelected ? !tableState.selected : buttonDeleteProps?.disabled,
        }}
        buttonDeleteProps={{
          ...buttonDeleteProps,
          disabled: enableDeleteWithSelected ? !tableState.selected : buttonDeleteProps?.disabled,
          disabledColor: theme.palette.grey['100'],
          onClick: () => setTableState(prev => ({ ...prev, showDelete: true }))
        }}
        customButtons={customButtons}
      />
      <Grid container className={classes.headerRow}>
        {cols.map(column => <Grid item xs={column.xs} className={classes.headerCell}>{column.header}</Grid>)}
      </Grid>
      {data?.map((item, index) =>
        <Grid container onClick={() => handleSelectIndex(index)} className={`${classes.tableRow} ${getRowStyle(index)}`}>
          {cols.map(column => {
            const { xs, editable, autocompleteProps, autocompleteSuggestions, inputProps, maskedProps, disableDate, value, onChangeItem, onClickRow } = column;

            return (
              <Grid item xs={xs} className={classes.cell} onClick={() => onClickRow && onClickRow(index)}>
                {!editable ? value(item) :
                  showEditComponent(
                    editable,
                    index,
                    autocompleteProps,
                    value(item),
                    onChangeItem,
                    autocompleteSuggestions,
                    inputProps,
                    maskedProps,
                    disableDate,
                  )
                }
              </Grid>
            );
          })}
        </Grid>
      )}
    </section>
  );
}

export default EditableTable;