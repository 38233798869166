/**
 * Enum de tipos de frente por conta
 *
 * @author Marcos Davi
 */
enum FreteContaEnum {
  CIF = 0,
  FOB = 1,
  TERCEIROS = 2,
  PROPRIO_REMETENTE = 3,
  PROPRIO_DESTINATARIO = 4
}

export default FreteContaEnum;
