import React, { useState, useEffect, useRef } from 'react';
// import { Dispatch } from 'redux';
// import Swal from '../swal/swal';
// import ScreensMap from './screens-map';
import { useStyles } from './form.styles';
// import { MainStateType } from 'root-states';
// import { useDispatch, useSelector } from 'react-redux';
import { Add, Check, Close } from '@mui/icons-material';
// import { DispatchAction } from 'root-states/root-dispatcher';
import ReadOnly, { ReadOnlyProps } from '../readonly/read-only';
// import ContextActions from 'root-states/actions/context-actions';
import { FormikErrors, FormikProps, FormikTouched } from 'formik';
// import { ContextStateType } from 'root-states/reducers/context-reducer';
import { SelectPropType } from '@bubotech/sumora-react-components/lib/select';
import { SwitchPropTypes } from '@bubotech/sumora-react-components/lib/switch';
// import { useKeyboardControls } from 'root-utils/hooks/use-keyboard-controls';
import { DatePickerPropType } from '@bubotech/sumora-react-components/lib/datepicker';
import { AutoCompletePropType } from '@bubotech/sumora-react-components/lib/autocomplete';
import { AutoComplete, DatePicker, Select, Switch, TextField } from '@bubotech/sumora-react-components';
// import useVerificaDados, { verificarDadosProps } from '../alertadadosnaosalvos/hooks/useVerificaDados';
import MaskedTextField, { MaskTypeEnum, MaskedTextFieldPropType } from '@bubotech/sumora-react-components/lib/maskedtextfield';
import { Grid, InputAdornment, Checkbox, TextFieldProps, Typography, Tooltip, IconButton, CheckboxProps } from '@mui/material';

export enum FormFieldTypeEnum {
  TEXTFIELD,
  AUTOCOMPLETE,
  DATE,
  SWITCH,
  SELECT,
  MULTILINE,
  READ_ONLY,
  MASKED_TEXTFIELD,
  CHECKBOX,
  EMPTY
}

export interface DatePickerProps extends Omit<DatePickerPropType, 'value' | 'renderInput' | 'onChange'> {
  onChange?: (value: Date) => void;
  renderInput?: JSX.Element;
}

export interface FormReadOnlyProps extends Omit<ReadOnlyProps, 'value' | 'label'> {}

export interface FormMasketTextFieldProps extends Omit<MaskedTextFieldPropType, 'typeMask'> {}

export interface Field {
  fieldName: string;
  label: string;
  fieldSize: number;
  fieldType: FormFieldTypeEnum;
  value?: string;
  autoCompleteProps?: AutoCompletePropType<any>;
  selectProps?: SelectPropType<any>;
  customItem?: JSX.Element;
  datePickerProps?: DatePickerProps;
  textFieldProps?: TextFieldProps;
  switchProps?: SwitchPropTypes;
  maskedTextFieldProps?: FormMasketTextFieldProps;
  readOnlyProps?: FormReadOnlyProps;
  checkBoxProps?: CheckboxProps;
}

export interface FormPropType<T> {
  fields: Field[];
  form: FormikProps<T>;
  showAdd?: boolean;
  showEdit?: boolean;
  onCancelEdit?: () => void;
  keepHistory?: boolean;
  useMainStack?: boolean;
  nextTab?: boolean;
  previousTab?: boolean;
  verificaDados?: boolean;
  // useVerificaDadosProps?: verificarDadosProps;
  saveOnEnter?: boolean;
  disableAddButton?: boolean;
  onFinishSave?: () => void;
  customSave?: () => void;
}

/**
 * Formulário
 * 
 * @author Marcos Davi <marcos.davi@kepha.com.br>
 * @param {FormPropType} props
 */
function Form<T>(props: FormPropType<T>): JSX.Element {
  const classes = useStyles();
  const { 
    fields, 
    form, 
    showAdd = false, 
    showEdit = false, 
    // verificaDados = false, 
    // // useVerificaDadosProps, 
    // keepHistory = false, 
    // useMainStack = true,
    nextTab = false,
    previousTab = false,
    saveOnEnter = true,
    disableAddButton = false,
    onCancelEdit,
    onFinishSave,
    customSave,
  } = props;
  
  const [key, setKey] = useState<number>(0);
  const lastKeyPressed = useRef<string>('');

  // const contextActions = new ContextActions(useDispatch<Dispatch<DispatchAction>>());
  // const contextProps = useSelector<MainStateType, ContextStateType>(state => state.contextReducer);

  let linefieldSize = 0;

  /**
   * Extrai o valor do campo do formulário
   * @param fieldName nome do atributo
   * @param item objeto para extrar o valor
   * @returns 
   */
  function getItemValue(fieldName: string, item: T | FormikErrors<T> | FormikTouched<T>) {
    const fields = fieldName.split('.');

    let current: any = item;
    while (fields.length) {
      if (typeof current !== 'object' || !current) return undefined;
      else current = current[fields.shift() as keyof T || '' as keyof T];
    }

    return current;
  }

  /**
   * Retorna se um campo do formulário tem erro
   * @param name nome do atributo
   * @returns booleano
   */
  function getItemError(name: string) {
    return Boolean(getItemValue(name, form.errors) && getItemValue(name, form.touched));
  }

  /**
   * Controla os eventos do teclado de acordo com o campo e a posição na grid
   * @param event Evento do teclado
   * @param isLast Booleano para dizer se é o último campo do formulário
   * @param field Propiedades do campo
   */
  function handleFieldKeyboardActions(event: React.KeyboardEvent<any>, isLast: boolean, field: Field) {
    if (!saveOnEnter && !nextTab) return;

    if (event.key === 'Tab' && isLast && nextTab && !showAdd) {
      event.preventDefault();
      // contextActions.tryChangeTab(contextProps.selectedTab + 1);
    } else if (event.key === 'Enter' && saveOnEnter && form.dirty) {
      // event.preventDefault();
      // Swal({
      //   icon: 'warning',
      //   title: 'Atenção',
      //   text: 'Gostaria de SALVAR as alterações feitas?',
      //   showCancelButton: true,
      //   showConfirmButton: true,
      //   cancelButtonText: 'CANCELAR',
      //   confirmButtonText: 'SALVAR'
      // }).then(option => {
      //   if (option.isConfirmed) {
      //     if (customSave) {
      //       customSave();
      //       return;
      //     }
      //     form.handleSubmit();
      //     onFinishSave && onFinishSave();
      //   };
      // })
    } else if (event.key === 'Escape' && previousTab) {
      event.preventDefault();
      // contextActions.tryChangeTab(contextProps.selectedTab - 1);
    }
  }

  /**
   * Função para controlar o evendo de redirecionamento para cadastro através de um autocomplete
   * @param event Evento do teclado
   * @param search Tela de cadastro que o autocomplete faz a busca
   * @param label Nome do cadastro
   */
  function handleAutoCompleteRedirect(event: React.KeyboardEvent<any>, search: string = '', label?: string) {    
    let url = '';
    // Object.keys(ScreensMap).forEach(key => {
    //   if (key.includes(search)) url = ScreensMap[key as keyof typeof ScreensMap];
    // })

    if (lastKeyPressed.current === 'Control' && event.key === ' ' && url) {
      // Swal({
      //   icon: 'question',
      //   title: `Não encontrou o que procurava?`,
      //   text: `Abrir cadastro de ${label}`,
      //   showCancelButton: false,
      //   showConfirmButton: true,
      //   confirmButtonText: 'Abrir'
      // }).then(option => {
      //   if (option.isConfirmed) window.open(url, "_blank");
      // });
    }

    lastKeyPressed.current = event.key;
  }

  /**
   * Renderiza um campo do formulário
   * @param field Configurações do campo
   */
  function renderField(field: Field, isLast: boolean) {
    const value = getItemValue(field.fieldName, form.values);

    const fieldCommonProps = {
      value: field.value ?? value,
      onBlur: form.handleBlur,
      name: field.fieldName,
      label: field.label,
      error: getItemError(field.fieldName),
      errorText: getItemError(field.fieldName) ? getItemValue(field.fieldName, form.errors) : '',
      helperText: getItemError(field.fieldName) ? getItemValue(field.fieldName, form.errors) : '',
      onKeyDown: (event: React.KeyboardEvent<any>) => handleFieldKeyboardActions(event, isLast, field),
    }

    const defaultChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      form.setFieldValue(e.target.name, e.target.value);
    }

    const defaultCheckClick = () => {
      form.setFieldValue(field.fieldName, getItemValue(field.fieldName, form.values) ? 0 : 1)
    }

    const fieldDict = {
      [FormFieldTypeEnum.AUTOCOMPLETE]: 
        <AutoComplete
          key={key}
          getLabel={opt => ''}
          getValue={opt => ''}
          onChangeValue={e => form.setFieldValue(field.fieldName, e ?? undefined)}
          {...fieldCommonProps}
          {...field.autoCompleteProps}
          inputProps={{
            ...field.autoCompleteProps,
            onKeyUp: (event: React.KeyboardEvent<any>) => handleAutoCompleteRedirect(event, field.autoCompleteProps?.searchField, field.label),
          }}
        />,
      [FormFieldTypeEnum.DATE]:
        <DatePicker
          onChange={(e: Date) => form.setFieldValue(field.fieldName, e)}
          {...fieldCommonProps}
          {...field.datePickerProps}
        />,
      [FormFieldTypeEnum.SELECT]: 
        <Select
          getOptionLabel={opt => opt.label}
          getOptionValue={opt => opt.value}
          options={[]}
          onChangeValue={e => form.setFieldValue(field.fieldName, e.value)}
          {...fieldCommonProps}
          {...field.selectProps}
        />,
      [FormFieldTypeEnum.TEXTFIELD]:
        <TextField
          onChange={defaultChange}
          {...fieldCommonProps}
          {...field.textFieldProps}
        />,
      [FormFieldTypeEnum.SWITCH]:
        <Switch
          checked={Boolean(value)}
          onChange={defaultCheckClick}
          {...fieldCommonProps}
          {...field.switchProps}
        />,
      [FormFieldTypeEnum.MULTILINE]:
        <TextField 
          multiline
          onChange={defaultChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <Typography variant='subtitle1'>{value ? (value as string)?.length : '0'}/200</Typography>
              </InputAdornment>
            )
          }}
          {...fieldCommonProps}
          {...field.textFieldProps}
          inputProps={{ maxLength: 200 }}
        />,
      [FormFieldTypeEnum.READ_ONLY]:
        <ReadOnly 
          {...fieldCommonProps}
          {...field.readOnlyProps}
        />,
      [FormFieldTypeEnum.MASKED_TEXTFIELD]:
        <MaskedTextField
          typeMask={MaskTypeEnum.MONEY}
          onChange={defaultChange}
          {...fieldCommonProps}
          {...field.maskedTextFieldProps}
        />,
      [FormFieldTypeEnum.CHECKBOX]:
        <div style={{ display: 'flex', alignItems: 'center', height: 70 }}>
          <Checkbox
            checked={Boolean(value)}
            onChange={defaultCheckClick}
            {...fieldCommonProps}
            {...field.checkBoxProps}
          />
          <div className={classes.label} onBlur={form.handleBlur} onClick={defaultCheckClick}>
            {field.label}
          </div>
        </div>,
      [FormFieldTypeEnum.EMPTY]: <></>
    }

    return fieldDict[field.fieldType];
  }

  function getItemClassName(fieldSize: number, type: FormFieldTypeEnum) {
    if (type === FormFieldTypeEnum.SWITCH) return classes.switchContainer;
    else {
      let newValue = linefieldSize + fieldSize;
      if (newValue < 12) {
        linefieldSize = newValue;
        return classes.gridCell;
      }
      else if (newValue === 12) linefieldSize = 0;
      else {
        linefieldSize = fieldSize;

        if (fieldSize < 12) return classes.gridCell;
      }
      
      return undefined;
    }
  }

  function getItemStyle(type: FormFieldTypeEnum, isMultiline: boolean = false) {
    if (type === FormFieldTypeEnum.MULTILINE) return { height: 130 };
    if ((type === FormFieldTypeEnum.READ_ONLY && isMultiline)) return { height: 150 };
    else return { height: 76 };
  }

  useEffect(() => setKey(Math.random()), [form.values])

  // useVerificaDados({
  //   funcaoPrincipalProps: {
  //     dirty: verificaDados ? form.dirty : false,
  //     ...useVerificaDadosProps?.funcaoPrincipalProps
  //   },
  //   handleSubmit: customSave ?? form.handleSubmit,
  //   ignoreTabChange: true,
  //   ...useVerificaDadosProps
  // })

  // useFormHistory<T>(form, keepHistory, keepHistory && useMainStack);

  // useKeyboardControls([], true, nextTab, previousTab);

  let isLastMultiline = false;

  return (
    <Grid container>
      {fields.map((field, index) => {
        const { fieldType, fieldSize, customItem } = field;
        const className = getItemClassName(fieldSize, fieldType);
        const isLast = Boolean(index === fields.length - 1);
        
        if (isLast && field.fieldType === FormFieldTypeEnum.MULTILINE) isLastMultiline = true;
        
        return (
          <Grid item xs={fieldSize ?? undefined} className={className} style={getItemStyle(fieldType, field?.readOnlyProps?.multiline)} key={index}>
            {customItem ?? renderField(field, isLast)}
          </Grid>
        )
      })}
      {showAdd && (
        <Grid item className={classes.switchContainer} style={isLastMultiline ? { height: 120 } : undefined}>
          <Tooltip title='Salvar'>
            <span>
              <IconButton 
                color='primary' 
                type='submit' 
                role='submit' 
                disabled={disableAddButton}
                onClick={() => {
                  if (customSave) {
                    customSave();
                    return;
                  }

                  form.handleSubmit();
                  onFinishSave && onFinishSave();
                }}
              >
                {showEdit ? <Check /> : <Add />}
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
      )}
      {showEdit && onCancelEdit && (
        <Grid item className={classes.switchContainer} style={isLastMultiline ? { height: 120 } : undefined}>
          <Tooltip title='Cancelar'>
            <span>
              <IconButton color='primary' type='submit' role='submit' onClick={onCancelEdit}>
                <Close color='error'/>
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
      )}
    </Grid>
  )
}

export default Form;

// /**
//  * Hook customizado para manter o histórico de valores de um formulário
//  * 
//  * @author Marcos Davi <marcos.davi@kepha.com.br>
//  * @param {FormikProps<T>} form formulário para manter histórico
//  * @param {Boolean} keepHistory atualiza a pilha de dados após uma mudança
//  * @param {Boolean} useMainStack utiliza a Web Storage para manter os dados do formulário principal salvos entre as abas
//  */
// export function useFormHistory<T = any>(form: FormikProps<T>, keepHistory: boolean = true, useMainStack: boolean = true) {
//   const lastKeyPressed = useRef<string>('');
//   const pastStack = useRef<FormikProps<T>[]>([]);
//   const futurePastStack = useRef<FormikProps<T>[]>([]);

//   const [shouldUpdate, setShouldUpdate] = useState(true);

//   useEffect(() => {
//     const keyDownHandler = (event: KeyboardEvent) => {
//       if (event.key === 'z' && lastKeyPressed.current === 'Control') {
//         setShouldUpdate(false);
//         if (pastStack.current.length) {
//           // Swal({
//           //   icon: 'warning',
//           //   title: 'Atenção',
//           //   text: 'Gostaria de DESFAZER a última alteração?',
//           //   showCancelButton: true,
//           //   showConfirmButton: true,
//           //   cancelButtonText: 'CANCELAR',
//           //   confirmButtonText: 'DESFAZER'
//           // }).then(option => {
//           //   if (option.isConfirmed) {
//           //     let currentValues = pastStack.current.pop();

//           //     if (currentValues) {
//           //       const pastValues = pastStack.current[pastStack.current.length - 1];
//           //       pastValues && form.setFormikState(pastValues);
//           //       futurePastStack.current.push(currentValues);
//           //     }
//           //   }
//           // })
//         }
      
//       } else if (event.key === 'y' && lastKeyPressed.current === 'Control') {
//         setShouldUpdate(false);
  
//         if (futurePastStack.current.length) {
//           // Swal({
//           //   icon: 'warning',
//           //   title: 'Atenção',
//           //   text: 'Gostaria de REFAZER a última alteração?',
//           //   showCancelButton: true,
//           //   showConfirmButton: true,
//           //   cancelButtonText: 'CANCELAR',
//           //   confirmButtonText: 'REFAZER'
//           // }).then(option => {
//           //   if (option.isConfirmed) {
//           //     const pastFutureValues = futurePastStack.current.pop();
          
//           //     if (pastFutureValues) {
//           //       pastStack.current.push(form);
//           //       form.setFormikState(pastFutureValues);
//           //     }
//           //   }
//           // })
//         }
//       } else if (event.key === 'Control') setShouldUpdate(true); 
      
//       setShouldUpdate(true);
//       lastKeyPressed.current = event.key;
//     }

//     if (keepHistory) document.addEventListener('keydown', keyDownHandler);

//     return () => {
//       document.removeEventListener('keydown', keyDownHandler);
//       useMainStack && localStorage.setItem('formStack', JSON.stringify({ past: pastStack.current, future: futurePastStack.current }))
//     };
    

//     // eslint-disable-next-line
//   }, [keepHistory, setShouldUpdate, futurePastStack.current, form, pastStack.current, useMainStack, lastKeyPressed]);


//   useEffect(() => {
//     if (useMainStack) {
//       const formStack = JSON.parse(localStorage.getItem('formStack') ?? '{}');
      
//       if (formStack.past) pastStack.current = formStack.past;
//       if (formStack.future) futurePastStack.current = formStack.future;
//     }
//   }, [useMainStack])

//   useEffect(() => {
//     const differentValues = Boolean(JSON.stringify(form?.values) !== JSON.stringify(pastStack.current[pastStack.current.length - 1]?.values));

//     if (shouldUpdate && differentValues) pastStack.current.push(form);
//   }, [form, pastStack, keepHistory, shouldUpdate])

//   const clearPastStack = () => pastStack.current = [];
//   const clearFutureStack = () => futurePastStack.current = [];

//   return { clearPastStack, clearFutureStack };
// }