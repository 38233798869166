// Converter string para números
function stringToNumber(value?: string, int?: boolean): number {
  if(!value) return 0;

  return parseFloat(value.replaceAll('.','').replace(',', '.'));
}

function numberToString(value: number, decimalScale?: number): string {
  return new Intl.NumberFormat('pt-br', { minimumFractionDigits: decimalScale || 0, maximumFractionDigits: decimalScale }).format(value);
}

export { stringToNumber, numberToString }