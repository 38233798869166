import RootApi from '../root-api';
import Resposta from '../models/respostaapi';
import Busca from '../models/busca';
import { AxiosResponse } from 'axios';
import URL from '../URL';


/**
 * Classes para buscar informações referentes ao faturamento
 *
 * @author Marcos Davi
 */
export default class FaturamentoAPI<E> extends RootApi {
  /**
  * Salva um novo objeto da entidade
  *
  * @param {E} values - Valores para salvar
  * @returns {Promise<AxiosResponse<E>>} Promise com a resposta e o objeto da entidade com o seus dados novos persistidos
  */
  public async save(values: E): Promise<AxiosResponse<E>> {
    return this.api.post<E>('/', values);
  }

  /**
   * Atualiza os dados de um objeto da entidade
   *
   * @param {E} values - Valores para atualizar
   * @returns {Promise<AxiosResponse<E>>} Promise com a resposta e o objeto da entidade atualizado
   */
  public async update(values: E): Promise<AxiosResponse<E>> {
    return this.api.put<E>('/', values);
  }

  /**
   * @param {Busca} busca - Filtros a serem usados na requisição
   * @returns {Promise<AxiosResponse<Resposta>>} Promise com a resposta com os dados da busca
   */
  public async findByPage(busca: Busca): Promise<AxiosResponse<Resposta>> {
    const search = busca.searchField ? ',' + busca.searchField + ':' + busca.searchFieldValue : '';
    const staticParams = busca.staticParams ?? ''
    return this.api.get<Resposta>(
      `?search=pag:${busca.page},orderField:${busca.orderField},orderType:${busca.orderType}${search}${staticParams}`,
      { signal: busca.controller }
    )
  }

  /**
  * Busca um objeto da entidade pelo ID
  *
  * @template T - Tipo da propriedade identificadora da entidade, por padrão assume "number"
  * @param {T} id - ID para buscar
  * @returns {Promise<AxiosResponse<E>>} Promise com a resposta com o objeto da entidade referente aquele ID
  */
  public async findById<T = string>(id: T): Promise<AxiosResponse<E>> {
    return this.api.get<E>(`/${id}`);
  }

  /**
   * Injeta as configurações da api
   */
  public constructor(value?: string, schema?: string) {
    super({ baseURL: `${URL.FATURAMENTOAPI + (value ?? '')}`, baseSchema: schema });
  }
}
