import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LinkFornecedor from './mc/cotacao/views/link-fornecedor';

import './App.css'


function createRoutes(): JSX.Element {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/:baseSchema/:id' element={<LinkFornecedor />}/>
      </Routes>
    </BrowserRouter>
  )
}

export default createRoutes;