import FaturamentoAPI from './faturamento';
import CondicaoPagamento from '../../mc/cotacao/models/condicaopgto';

/**
 * API de Condições de Pagamento
 *
 * @author Marcos Davi <marcos.davi@kepha.com.br>
 * @extends {RootApi}
 */
class CondicaoPagamentoAPI extends FaturamentoAPI<CondicaoPagamento> {
  /**
  * Injeta a instância do axios com o endpoint base referente a condições de pagamento
  */
  public constructor(schema?: string) {
    super('condicao-pagamento/cotacao-fornecedor', schema);
  }
}

export default CondicaoPagamentoAPI;
