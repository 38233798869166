import FaturamentoAPI from './faturamento';
import FormaPagamento from '../../mc/cotacao/models/formapagamento';

/**
 * API de Formas de Pagamento
 *
 * @author Marcos Davi <marcos.davi@kepha.com.br>
 * @extends {RootApi}
 */
class FormaPagamentoAPI extends FaturamentoAPI<FormaPagamento> {
  /**
  * Injeta a instância do axios com o endpoint base referente a formas de pagamento
  */
  public constructor(schema?: string) {
    super('forma-pagamento/cotacao-fornecedor', schema);
  }
}

export default FormaPagamentoAPI;
