import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { ThemeProvider } from '@mui/material';
import { ThemeProvider as StylesThemeProvider } from '@mui/styles';
import { theme } from './utils/constants';
import createRoutes from './App';

const routes = createRoutes();

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <StylesThemeProvider theme={theme}>
        {routes}
      </StylesThemeProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
